import { lazy } from "react";
import { Navigate } from "react-router-dom";
// import { AuthCheck } from './AuthCheck.js'; // Import the created components




/***** Pages ****/
const Dashboard = lazy(() => import("../views/auth/Dashboard.jsx"));
const NotFound = lazy(() => import("../views/NotFound.js"));
const Login = lazy(()=>import('../views/unauth/login.jsx'));
const ProductInfo = lazy(()=>import('../views/auth/ProductData.jsx'));
const DataFiles = lazy(()=>import('../views/auth/DataFiles.jsx'));
const Installations =lazy(()=>import('../views/auth/Installation.jsx'));
const AddDevice = lazy(()=>import('../views/auth/device/AddDevice.jsx'));
const ViewDevice = lazy(()=>import('../views/auth/device/ViewDevice.jsx'));
const EditDevice = lazy(()=>import('../views/auth/device/EditDevice.jsx'));

/*****Routes******/


const ThemeRoutes = [
  { path: "/", exact: true, element: <Login /> },
  { path: "/login", exact: true, element: <Login /> },

  {
    path: "/",
    // element: (
    //   // <AuthCheck>
    //   //   {/* <FullLayout /> */}
    //   // </AuthCheck>
    // ),
    children: [
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/product-info", exact: true, element: <ProductInfo /> },
      { path: "/data-files", exact: true, element: <DataFiles /> },
      { path: "/installations", exact: true, element: <Installations /> },
      // { path: "/ProductInfo", exact: true, element: <Dashboard /> },

      
      { path: "/devices", exact: true, element: <ViewDevice /> },
      { path: "/add-device", exact: true, element: <AddDevice /> },
      { path: "/device/edit/:id", exact: true, element: <EditDevice /> },
      // { path: "/users/edit/:id", extact: true, element: <EditUser /> },

      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  { path: "/404", element: <NotFound /> },
];

export default ThemeRoutes;
