import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import App from './App';
import Loader from "./components/layout/Loader";

import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  timeout: 10000,
  position: positions.TOP_RIGHT,
  transitions: transitions.SCALE,
}

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Create a root and render the App component
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Suspense fallback={<Loader />}>
      <HashRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </HashRouter>
    </Suspense>
  );
}
